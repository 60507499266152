/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * An object that represents a latitude/longitude pair. This is expressed as a
 * pair of doubles to represent degrees latitude and degrees longitude. Unless
 * specified otherwise, this must conform to the
 * <a href="http://www.unoosa.org/pdf/icg/2012/template/WGS_84.pdf">WGS84
 * standard</a>. Values must be within normalized ranges.
 * @export
 * @interface TypeLatLng
 */
export interface TypeLatLng {
    /**
     * The latitude in degrees. It must be in the range [-90.0, +90.0].
     * @type {number}
     * @memberof TypeLatLng
     */
    latitude?: number;
    /**
     * The longitude in degrees. It must be in the range [-180.0, +180.0].
     * @type {number}
     * @memberof TypeLatLng
     */
    longitude?: number;
}

/**
 * Check if a given object implements the TypeLatLng interface.
 */
export function instanceOfTypeLatLng(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TypeLatLngFromJSON(json: any): TypeLatLng {
    return TypeLatLngFromJSONTyped(json, false);
}

export function TypeLatLngFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeLatLng {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function TypeLatLngToJSON(value?: TypeLatLng | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

