import * as css from './FeedRecommendPost.css'
import FeedRecommendPostItemSkeleton from './FeedRecommendPostItemSkeleton'
import SectionSkeleton from '../lib/section/SectionSkeleton'

const FeedRecommendPostSkeleton = () => {
  return (
    <SectionSkeleton
      sectionTitleProps={{
        isRightButton: false,
      }}
    >
      <div className={css.pageItem}>
        <FeedRecommendPostItemSkeleton />
        <FeedRecommendPostItemSkeleton />
        <FeedRecommendPostItemSkeleton />
      </div>
    </SectionSkeleton>
  )
}

export default FeedRecommendPostSkeleton
