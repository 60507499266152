import { useCallback } from 'react'

import { karrotBridge } from '@src/bridge/bridge'
import { IS_LOCAL } from '@src/constants'

import { objectKeysToSnake } from '../utils'
import { logLevel } from '../logLevel'

export const useLogger = () => {
  const consoleLogParams = useCallback(
    (name: string, params: Record<string, any>) => {
      logLevel.log({
        target: 'KARROT',
        name: `client_${name}`,
        params: params,
      })
    },
    []
  )

  const karrotBridgeLog = useCallback(
    (name: string, params: Record<string, any>) => {
      if (IS_LOCAL) {
        consoleLogParams(name, objectKeysToSnake(params))
        return
      }

      karrotBridge.logEvent({
        analytics: {
          target: 'KARROT',
          name: `client_${name}`,
          params: JSON.stringify(objectKeysToSnake(params)),
        },
      })
    },
    [consoleLogParams]
  )

  return karrotBridgeLog
}
