/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';

/**
 * 
 * @export
 * @interface ViewTypeActionableBoxButtonGroupButton
 */
export interface ViewTypeActionableBoxButtonGroupButton {
    /**
     * 
     * @type {string}
     * @memberof ViewTypeActionableBoxButtonGroupButton
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ViewTypeActionableBoxButtonGroupButton
     */
    iconImageUrl: string;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof ViewTypeActionableBoxButtonGroupButton
     */
    actions: Array<V1Action>;
    /**
     * 
     * @type {string}
     * @memberof ViewTypeActionableBoxButtonGroupButton
     */
    id: string;
}

/**
 * Check if a given object implements the ViewTypeActionableBoxButtonGroupButton interface.
 */
export function instanceOfViewTypeActionableBoxButtonGroupButton(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "iconImageUrl" in value;
    isInstance = isInstance && "actions" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ViewTypeActionableBoxButtonGroupButtonFromJSON(json: any): ViewTypeActionableBoxButtonGroupButton {
    return ViewTypeActionableBoxButtonGroupButtonFromJSONTyped(json, false);
}

export function ViewTypeActionableBoxButtonGroupButtonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewTypeActionableBoxButtonGroupButton {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'iconImageUrl': json['icon_image_url'],
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
        'id': json['id'],
    };
}

export function ViewTypeActionableBoxButtonGroupButtonToJSON(value?: ViewTypeActionableBoxButtonGroupButton | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'icon_image_url': value.iconImageUrl,
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
        'id': value.id,
    };
}

