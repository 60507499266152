/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1ViewTypeMoreButton } from './V1ViewTypeMoreButton';
import {
    V1ViewTypeMoreButtonFromJSON,
    V1ViewTypeMoreButtonFromJSONTyped,
    V1ViewTypeMoreButtonToJSON,
} from './V1ViewTypeMoreButton';
import type { V1ViewTypeSectionBottomButton } from './V1ViewTypeSectionBottomButton';
import {
    V1ViewTypeSectionBottomButtonFromJSON,
    V1ViewTypeSectionBottomButtonFromJSONTyped,
    V1ViewTypeSectionBottomButtonToJSON,
} from './V1ViewTypeSectionBottomButton';
import type { V1ViewTypeShortView } from './V1ViewTypeShortView';
import {
    V1ViewTypeShortViewFromJSON,
    V1ViewTypeShortViewFromJSONTyped,
    V1ViewTypeShortViewToJSON,
} from './V1ViewTypeShortView';

/**
 * 
 * @export
 * @interface V1ViewTypeSectionLocalBusinessStory
 */
export interface V1ViewTypeSectionLocalBusinessStory {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeSectionLocalBusinessStory
     */
    title: string;
    /**
     * 
     * @type {Array<V1ViewTypeShortView>}
     * @memberof V1ViewTypeSectionLocalBusinessStory
     */
    viewTypes: Array<V1ViewTypeShortView>;
    /**
     * 
     * @type {V1ViewTypeMoreButton}
     * @memberof V1ViewTypeSectionLocalBusinessStory
     */
    moreButton: V1ViewTypeMoreButton;
    /**
     * 
     * @type {V1ViewTypeSectionBottomButton}
     * @memberof V1ViewTypeSectionLocalBusinessStory
     */
    uploadButton: V1ViewTypeSectionBottomButton;
}

/**
 * Check if a given object implements the V1ViewTypeSectionLocalBusinessStory interface.
 */
export function instanceOfV1ViewTypeSectionLocalBusinessStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "viewTypes" in value;
    isInstance = isInstance && "moreButton" in value;
    isInstance = isInstance && "uploadButton" in value;

    return isInstance;
}

export function V1ViewTypeSectionLocalBusinessStoryFromJSON(json: any): V1ViewTypeSectionLocalBusinessStory {
    return V1ViewTypeSectionLocalBusinessStoryFromJSONTyped(json, false);
}

export function V1ViewTypeSectionLocalBusinessStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeSectionLocalBusinessStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'viewTypes': ((json['view_types'] as Array<any>).map(V1ViewTypeShortViewFromJSON)),
        'moreButton': V1ViewTypeMoreButtonFromJSON(json['more_button']),
        'uploadButton': V1ViewTypeSectionBottomButtonFromJSON(json['upload_button']),
    };
}

export function V1ViewTypeSectionLocalBusinessStoryToJSON(value?: V1ViewTypeSectionLocalBusinessStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'view_types': ((value.viewTypes as Array<any>).map(V1ViewTypeShortViewToJSON)),
        'more_button': V1ViewTypeMoreButtonToJSON(value.moreButton),
        'upload_button': V1ViewTypeSectionBottomButtonToJSON(value.uploadButton),
    };
}

