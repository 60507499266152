import { NEARBY_WEB_BASE_URL } from '@src/constants'

import * as css from './DEV_FloatingNearbyShortcutButton.css'

const DEV_FloatingNearbyShortcutButton = () => {
  const handleClick = () => {
    window.location.replace(`${NEARBY_WEB_BASE_URL}`)
  }

  return (
    <div className={css.container} onClick={handleClick}>
      내 근처로 이동
    </div>
  )
}

export default DEV_FloatingNearbyShortcutButton
