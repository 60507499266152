/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1SearchResultLocalProfile } from './V1SearchResultLocalProfile';
import {
    V1SearchResultLocalProfileFromJSON,
    V1SearchResultLocalProfileFromJSONTyped,
    V1SearchResultLocalProfileToJSON,
} from './V1SearchResultLocalProfile';
import type { V1SearchResultViewType } from './V1SearchResultViewType';
import {
    V1SearchResultViewTypeFromJSON,
    V1SearchResultViewTypeFromJSONTyped,
    V1SearchResultViewTypeToJSON,
} from './V1SearchResultViewType';

/**
 * 
 * @export
 * @interface V1SearchResultItem
 */
export interface V1SearchResultItem {
    /**
     * 
     * @type {V1SearchResultViewType}
     * @memberof V1SearchResultItem
     */
    searchResultViewType: V1SearchResultViewType;
    /**
     * 
     * @type {V1SearchResultLocalProfile}
     * @memberof V1SearchResultItem
     */
    searchResultLocalProfile?: V1SearchResultLocalProfile;
}

/**
 * Check if a given object implements the V1SearchResultItem interface.
 */
export function instanceOfV1SearchResultItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchResultViewType" in value;

    return isInstance;
}

export function V1SearchResultItemFromJSON(json: any): V1SearchResultItem {
    return V1SearchResultItemFromJSONTyped(json, false);
}

export function V1SearchResultItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchResultItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchResultViewType': V1SearchResultViewTypeFromJSON(json['search_result_view_type']),
        'searchResultLocalProfile': !exists(json, 'search_result_local_profile') ? undefined : V1SearchResultLocalProfileFromJSON(json['search_result_local_profile']),
    };
}

export function V1SearchResultItemToJSON(value?: V1SearchResultItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_result_view_type': V1SearchResultViewTypeToJSON(value.searchResultViewType),
        'search_result_local_profile': V1SearchResultLocalProfileToJSON(value.searchResultLocalProfile),
    };
}

