/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1CommerceCoupon } from './V1CommerceCoupon';
import {
    V1CommerceCouponFromJSON,
    V1CommerceCouponFromJSONTyped,
    V1CommerceCouponToJSON,
} from './V1CommerceCoupon';

/**
 * 
 * @export
 * @interface V1PopularCommerceCouponSection
 */
export interface V1PopularCommerceCouponSection {
    /**
     * 
     * @type {Array<V1CommerceCoupon>}
     * @memberof V1PopularCommerceCouponSection
     */
    commerceCoupons: Array<V1CommerceCoupon>;
    /**
     * 
     * @type {string}
     * @memberof V1PopularCommerceCouponSection
     */
    viewMoreTargetUri: string;
}

/**
 * Check if a given object implements the V1PopularCommerceCouponSection interface.
 */
export function instanceOfV1PopularCommerceCouponSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commerceCoupons" in value;
    isInstance = isInstance && "viewMoreTargetUri" in value;

    return isInstance;
}

export function V1PopularCommerceCouponSectionFromJSON(json: any): V1PopularCommerceCouponSection {
    return V1PopularCommerceCouponSectionFromJSONTyped(json, false);
}

export function V1PopularCommerceCouponSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PopularCommerceCouponSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commerceCoupons': ((json['commerce_coupons'] as Array<any>).map(V1CommerceCouponFromJSON)),
        'viewMoreTargetUri': json['view_more_target_uri'],
    };
}

export function V1PopularCommerceCouponSectionToJSON(value?: V1PopularCommerceCouponSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commerce_coupons': ((value.commerceCoupons as Array<any>).map(V1CommerceCouponToJSON)),
        'view_more_target_uri': value.viewMoreTargetUri,
    };
}

