import {
  type SearchTypingStepParams,
  type HomeStepParams,
  type SearchStepParams,
} from './params'

export const FeedStepKeys = [
  'home', // 홈
  'searchTyping', // 검색 인풋 클릭시 검색 타이핑 화면
  'search', // 검색 화면
] as const

export const OtherStepKeys = [] as const

export type FeedStepKeys = (typeof FeedStepKeys)[number]
export type OtherStepKeys = (typeof OtherStepKeys)[number]
export type LocalMapStepKeys = FeedStepKeys | OtherStepKeys

type HomeStep = {
  step: 'home'
  params: HomeStepParams
}

type SearchTypingStep = {
  step: 'searchTyping'
  params: SearchTypingStepParams
}

type SearchStep = {
  step: 'search'
  params: SearchStepParams
}

export type LocalMapSteps = HomeStep | SearchTypingStep | SearchStep
