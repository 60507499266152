/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1Action } from './V1Action';
import {
    V1ActionFromJSON,
    V1ActionFromJSONTyped,
    V1ActionToJSON,
} from './V1Action';

/**
 * 
 * @export
 * @interface V1ViewTypeShortView
 */
export interface V1ViewTypeShortView {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeShortView
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeShortView
     */
    thumbnailImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeShortView
     */
    distanceDisplayText: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeShortView
     */
    durationDisplayText: string;
    /**
     * 
     * @type {Array<V1Action>}
     * @memberof V1ViewTypeShortView
     */
    actions: Array<V1Action>;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeShortView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeShortView
     */
    description: string;
}

/**
 * Check if a given object implements the V1ViewTypeShortView interface.
 */
export function instanceOfV1ViewTypeShortView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "thumbnailImageUrl" in value;
    isInstance = isInstance && "distanceDisplayText" in value;
    isInstance = isInstance && "durationDisplayText" in value;
    isInstance = isInstance && "actions" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function V1ViewTypeShortViewFromJSON(json: any): V1ViewTypeShortView {
    return V1ViewTypeShortViewFromJSONTyped(json, false);
}

export function V1ViewTypeShortViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeShortView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'thumbnailImageUrl': json['thumbnail_image_url'],
        'distanceDisplayText': json['distance_display_text'],
        'durationDisplayText': json['duration_display_text'],
        'actions': ((json['actions'] as Array<any>).map(V1ActionFromJSON)),
        'id': json['id'],
        'description': json['description'],
    };
}

export function V1ViewTypeShortViewToJSON(value?: V1ViewTypeShortView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'thumbnail_image_url': value.thumbnailImageUrl,
        'distance_display_text': value.distanceDisplayText,
        'duration_display_text': value.durationDisplayText,
        'actions': ((value.actions as Array<any>).map(V1ActionToJSON)),
        'id': value.id,
        'description': value.description,
    };
}

