import { vars } from '@seed-design/design-token'

import { HOME_SEARCH_RECOMMENDATION_CHIP_HEIGHT_PX } from '@src/pages/home-activity/_constants/size'
import { Skeleton } from '@src/react-utils/skeleton'

import * as css from './SearchRecommendationChips.css'

const SearchRecommendationChipsSkeleton = ({
  chipCount = 5,
}: {
  chipCount?: number
}) => {
  return (
    <div className={css.chipButtons}>
      {Array.from({ length: chipCount }).map((_, index) => (
        <Skeleton
          key={index}
          height={`${HOME_SEARCH_RECOMMENDATION_CHIP_HEIGHT_PX}px`}
          width="80px"
          border={{ radius: 'var(--seed-extended-fab-corner-radius)' }}
          color={vars.$scale.color.gray800}
          boxshadow="0px 1px 3px 0px rgba(0, 0, 0, 0.28)"
        />
      ))}
    </div>
  )
}

export default SearchRecommendationChipsSkeleton
