/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1ViewTypeCommerceCouponBusinessProfile
 */
export interface V1ViewTypeCommerceCouponBusinessProfile {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCouponBusinessProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCouponBusinessProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCouponBusinessProfile
     */
    profileImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeCommerceCouponBusinessProfile
     */
    categoryName: string;
}

/**
 * Check if a given object implements the V1ViewTypeCommerceCouponBusinessProfile interface.
 */
export function instanceOfV1ViewTypeCommerceCouponBusinessProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "profileImageUrl" in value;
    isInstance = isInstance && "categoryName" in value;

    return isInstance;
}

export function V1ViewTypeCommerceCouponBusinessProfileFromJSON(json: any): V1ViewTypeCommerceCouponBusinessProfile {
    return V1ViewTypeCommerceCouponBusinessProfileFromJSONTyped(json, false);
}

export function V1ViewTypeCommerceCouponBusinessProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeCommerceCouponBusinessProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'profileImageUrl': json['profile_image_url'],
        'categoryName': json['category_name'],
    };
}

export function V1ViewTypeCommerceCouponBusinessProfileToJSON(value?: V1ViewTypeCommerceCouponBusinessProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'profile_image_url': value.profileImageUrl,
        'category_name': value.categoryName,
    };
}

