import { z } from 'zod'

export const SearchQueryFrom = [
  'recent',
  'category_suggestion',
  'suggested',
  'recommendation_chip',
  'typed',
  'unknown',
] as const

export const HomeStepParamsSchema = z.object({
  selectedPin: z.string().nullable().default(null),
  localProfileId: z.string().nullable().default(null),
})

export const SearchTypingStepParamsSchema = z.object({
  query: z.string().nullable().default(null),
})

export const SearchStepParamsSchema = z.object({
  query: z.string().nullable().default(null),
  selectedPin: z.string().nullable().default(null),
  localProfileId: z.string().nullable().default(null),
  queryFrom: z.enum(SearchQueryFrom).default('unknown'),
})

export type HomeStepParams = z.infer<typeof HomeStepParamsSchema>

export type SearchTypingStepParams = z.infer<
  typeof SearchTypingStepParamsSchema
>

export type SearchStepParams = z.infer<typeof SearchStepParamsSchema>
