import { useMemo } from 'react'

export function useQueryParams<
  T extends Record<string, never> | Record<string, string>,
>(): Partial<T> {
  // @ts-ignore
  return useMemo(() => {
    return getQueryFromUrl(window.location.href)
  }, [])
}

export function getQueryFromUrl<T extends Record<string, string>>(
  url: string
): Partial<T> {
  const result: Partial<T> = {}
  const split = url.split('?')?.[1]

  if (!split) {
    return {}
  }

  const urlParams = new URLSearchParams(url.split('?')?.[1])

  urlParams.forEach((value, key) => {
    result[key as keyof T] = value as T[keyof T]
  })
  return result
}
