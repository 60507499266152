/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1ViewTypeBusinessPost } from './V1ViewTypeBusinessPost';
import {
    V1ViewTypeBusinessPostFromJSON,
    V1ViewTypeBusinessPostFromJSONTyped,
    V1ViewTypeBusinessPostToJSON,
} from './V1ViewTypeBusinessPost';
import type { V1ViewTypeMoreButton } from './V1ViewTypeMoreButton';
import {
    V1ViewTypeMoreButtonFromJSON,
    V1ViewTypeMoreButtonFromJSONTyped,
    V1ViewTypeMoreButtonToJSON,
} from './V1ViewTypeMoreButton';

/**
 * 
 * @export
 * @interface V1ViewTypeSectionRecommendPost
 */
export interface V1ViewTypeSectionRecommendPost {
    /**
     * 
     * @type {string}
     * @memberof V1ViewTypeSectionRecommendPost
     */
    title: string;
    /**
     * 
     * @type {Array<V1ViewTypeBusinessPost>}
     * @memberof V1ViewTypeSectionRecommendPost
     */
    viewTypes: Array<V1ViewTypeBusinessPost>;
    /**
     * 
     * @type {V1ViewTypeMoreButton}
     * @memberof V1ViewTypeSectionRecommendPost
     */
    moreButton: V1ViewTypeMoreButton;
}

/**
 * Check if a given object implements the V1ViewTypeSectionRecommendPost interface.
 */
export function instanceOfV1ViewTypeSectionRecommendPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "viewTypes" in value;
    isInstance = isInstance && "moreButton" in value;

    return isInstance;
}

export function V1ViewTypeSectionRecommendPostFromJSON(json: any): V1ViewTypeSectionRecommendPost {
    return V1ViewTypeSectionRecommendPostFromJSONTyped(json, false);
}

export function V1ViewTypeSectionRecommendPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ViewTypeSectionRecommendPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'viewTypes': ((json['view_types'] as Array<any>).map(V1ViewTypeBusinessPostFromJSON)),
        'moreButton': V1ViewTypeMoreButtonFromJSON(json['more_button']),
    };
}

export function V1ViewTypeSectionRecommendPostToJSON(value?: V1ViewTypeSectionRecommendPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'view_types': ((value.viewTypes as Array<any>).map(V1ViewTypeBusinessPostToJSON)),
        'more_button': V1ViewTypeMoreButtonToJSON(value.moreButton),
    };
}

