import { type z } from 'zod'

import {
  HomeStepParamsSchema,
  SearchStepParamsSchema,
  SearchTypingStepParamsSchema,
} from './params'
import { type LocalMapStepKeys } from './type'

const schemaMap = {
  home: HomeStepParamsSchema,
  searchTyping: SearchTypingStepParamsSchema,
  search: SearchStepParamsSchema,
} as const

type SchemaMap = typeof schemaMap
type ParamsType<T extends keyof SchemaMap> = z.infer<SchemaMap[T]>

export const parseParams = <T extends LocalMapStepKeys>(
  stepKey: T,
  params: unknown
): ParamsType<T> => {
  const schema = schemaMap[stepKey]
  return schema.parse(params)
}
